import React from 'react';
import Layout from '@/layouts/main';
import { Mixpanel } from '@/utils';
import AboutUs from '@/views/about-us';

export default function AboutUsPage() {
  React.useEffect(() => Mixpanel.track(`View About Us Page`), []);

  return (
    <Layout
      title="About Us - Transforming mental health through the gut"
      description="We aim to reduce over-dependence on pills by using good food to better the health
outcomes of people living with anxiety and depression."
      active="about-us"
    >
      <AboutUs />
    </Layout>
  );
}
